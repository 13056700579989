import { NavitiaObject } from "./navitia-object";
import { StopAreas } from "./stop-areas";
import { JourneySolutionBuilder } from "./journey-solution";
import { Poi } from "./poi";
import { StopPoint } from "./stop-point";

export function NavitiaBuilder() {}

NavitiaBuilder.prototype.stopAreas = function () {
  return new StopAreas();
};

NavitiaBuilder.prototype.stopPoint = function () {
  return new StopPoint();
};

NavitiaBuilder.prototype.journeySolution = function () {
  return new JourneySolutionBuilder();
};

NavitiaBuilder.prototype.poi = function () {
  return new Poi();
};
