const modes_config = {
    item: '.traffic-home__mode',
    item_checked: 'input[type=checkbox]:checked',
    active_disruptions: '.active-disruptions',
    future_disruptions: '.future-disruptions',
    traffic_content: '.ctp-traffic',
    mode: '.disruption__lines-by-mode',
    lines_item: '.disruption__lines-item',
    reset_modes: 'reset_traffic_modes'
};

export function displayDiscruptionsByModeEventListener() {
    const trafficContent = document.querySelector(modes_config.traffic_content);
    if (!trafficContent) {
        return;
    }

    clickOnModes(trafficContent);
    emptyModeEventListener();
    displaySelectedModeEventListener();
    resetModesEventListener(trafficContent);
}

function clickOnModes(trafficContent) {
    trafficContent.querySelectorAll(modes_config.item)?.forEach(function (mode) {
        mode.addEventListener('change', function (e) {
            const activeDisruptionsEl = trafficContent.querySelector(modes_config.active_disruptions);
            const futureDisruptionsEl = trafficContent.querySelector(modes_config.future_disruptions);
            const numberOfcheckedItem = trafficContent.querySelectorAll(modes_config.item_checked).length;

            manageModeSection(activeDisruptionsEl, e.target.dataset.modeId, e.target.checked, numberOfcheckedItem);
            manageModeSection(futureDisruptionsEl, e.target.dataset.modeId, e.target.checked, numberOfcheckedItem);

            manageEmptyMode();
            document.body.dispatchEvent(new CustomEvent('check_empty_section'));
        });
    });
}

function manageModeSection(el, id, checked, numberOfcheckedItem) {
    const modes = el?.querySelectorAll(modes_config.mode);
    modes.forEach(function (mode) {
        if (checked) {
            if (mode.dataset.modeId !== id && !mode.dataset.selectedMode) {
                mode.classList.add('gl-hide');
            }
            if (mode.dataset.modeId === id) {
                mode.dataset.selectedMode = "true";
                mode.classList.remove('gl-hide');
            }

            return;
        }

        if (mode.dataset.modeId === id) {
            if (!mode.classList.contains('gl-hide')) {
                mode.classList.add('gl-hide');
            }
        }
    });

    if (numberOfcheckedItem === 0) {
        displayAllModes(modes);
    }
}

function displayAllModes(modes) {
    modes?.forEach(function (mode) {
        mode.classList.remove('gl-hide');
        delete mode.dataset.selectedMode;
    });
}

function emptyModeEventListener() {
    document.body.addEventListener('empty_lines_by_mode', function (e) {
        manageEmptyMode(e.detail?.type);
    })
}

function manageEmptyMode(type) {
    const selectedMode = document.querySelectorAll('[data-selected-mode]').length;
    if (selectedMode > 0 && type === 'display-all') {
        return;
    }

    document.querySelectorAll(modes_config.mode).forEach(function (mode) {
        if (type === 'display-all') {
            mode.classList.remove('gl-hide');
            return;
        }

        let emptyLinesByMode = true;
        mode.querySelectorAll(modes_config.lines_item).forEach(function (line) {
            if (!line.classList.contains('gl-hide')) {
                emptyLinesByMode = false;
            }
        })

        if (emptyLinesByMode) {
            mode.classList.add('gl-hide');
        }
    });
}

function displaySelectedModeEventListener() {
    document.body.addEventListener('display_seleced_modes', function () {
        const selectedModes = [];
        document.querySelectorAll('[data-selected-mode]')?.forEach(function (mode) {
            mode.classList.remove('gl-hide');
            mode.parentElement?.classList.remove('gl-hide');
            if (!selectedModes.includes(mode.dataset.modeId)) {
                selectedModes.push(mode.dataset.modeId);
            }
        });
    });
}

function resetModesEventListener(trafficContent) {
    document.body.addEventListener(modes_config.reset_modes, function () {
        trafficContent.querySelectorAll(modes_config.item).forEach(function (mode) {
            mode.checked = false;
        })
    });
}
