import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export const POI_TYPE = "poi";

export function Poi() {
  this.internal = {
    id: null,
    type: "poi",
    data: {
      poiType: null, // multiple poi like vls/parking...
      icon: null,
      latitude: null,
      longitude: null,
      availablePlaces: null,
      handicappedPlaces: null,
      totalStands: null,
    },
  };
}

// stop_area_lines_direction

/**
 *
 * @param id Poi I
 * @param poiType Poi subtype
 * @param icon icon of poi
 * @param latitude
 * @param longitude
 * @param name name of poi
 * @param address
 * @param availablePlaces
 * @param handicappedPlaces
 * @param totalStands
 * @returns {Poi}
 */
Poi.prototype.setPoi = function (
  id,
  poiType,
  icon,
  latitude,
  longitude,
  name,
  address,
  availablePlaces = null,
  handicappedPlaces = null,
  totalStands = null
) {
  this.internal.id = id;
  this.internal.type = "poi";
  this.internal.data.poiType = poiType;
  this.internal.data.icon = icon;
  this.internal.data.latitude = latitude;
  this.internal.data.longitude = longitude;
  this.internal.data.name = name;
  this.internal.data.address = address;
  this.internal.data.availablePlaces = availablePlaces;
  this.internal.data.handicappedPlaces = handicappedPlaces;
  this.internal.data.totalStands = totalStands;
  return this;
};

Poi.prototype.getNavitiaObject = function () {
  return this.internal;
};

/**
 * @param {Element} element
 */
Poi.prototype.buildFromElement = function (element) {
  let params = JSON.parse(
    element.getAttribute(DataAttributesConstant.favoritesParams)
  );

  this.setPoi(
    params.id,
    params.data.poiType,
    params.data.icon,
    params.data.latitude,
    params.data.longitude,
    params.data.name,
    params.data.address,
    params.data.availablePlaces ?? [],
    params.data.handicappedPlaces ?? [],
    params.data.totalStands ?? []
  );
};
