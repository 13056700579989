import {autocompleteParams} from './constants';

export function manageTrafficAutocomplete() {
    // initialize autocompletion
    const trafficInput = JKisio(autocompleteParams.input_id);
    trafficInput.autocomplete();
    Kisio.Callbacks.autocomplete();

    trafficInput.on('propertychange input', function (e) {
        if (e.target.value === "") {
            dispatchInputEmptyValueEvent()
            JKisio(autocompleteParams.clear_btn).hide();
        } else {
            JKisio(autocompleteParams.clear_btn).show();
        }
    });
}

function dispatchInputEmptyValueEvent() {
    document.body.dispatchEvent(new CustomEvent(autocompleteParams.input_value_is_empty));
}
