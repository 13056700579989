import { NavitiaObject } from "./navitia-object";
import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export function JourneySolutionBuilder() {
  this.internal = {
    id: null,
    type: "journey",
    data: {
      from: null,
      fromCoord: null,
      to: null,
      toCoord: null,
      linkHref: null,
    },
  };
}

/**
 * @param {string} from
 * @param {string} to
 * @param {string} linkHref
 * @param {string} fromCoord
 * @param {string} toCoord
 * @returns {JourneySolutionBuilder}
 */
JourneySolutionBuilder.prototype.setJourney = function (from, to, linkHref, fromCoord, toCoord) {
  this.internal.id = this.hashUrl(this.sortQueryParams(linkHref));
  // this.internal.debugId = this.sortQueryParams(linkHref);
  this.internal.data.from = from;
  this.internal.data.fromCoord = fromCoord;
  this.internal.data.to = to;
  this.internal.data.toCoord = toCoord;
  this.internal.data.linkHref = this.sortQueryParams(linkHref);
  return this;
};

JourneySolutionBuilder.prototype.getNavitiaObject = function () {
  return this.internal;
};

JourneySolutionBuilder.prototype.hashUrl = function hashUrl(url) {
  let hash = 5381;
  for (let i = 0; i < url.length; i++) {
    const char = url.charCodeAt(i);
    hash = (hash << 5) + hash + char; // hash * 33 + char
  }
  return "id-" + (hash >>> 0).toString(16); // Convert to an unsigned 32-bit integer and then to a hexadecimal string
};

JourneySolutionBuilder.prototype.sortQueryParams = function (url) {
  const urlObj = new URL(url);

  // Extract query parameters
  const queryParams = [...urlObj.searchParams.entries()];

  // Filter out allowed_id[] with a value length less than 2
  const filteredQueryParams = queryParams.filter(([key, value]) => {
    if (key === "allowed_id[]") {
      return value.length >= 2;
    }
    return true;
  });

  // Sort filtered query parameters
  const sortedSearchParams = new URLSearchParams(filteredQueryParams.sort());

  // Clear the existing search parameters
  urlObj.search = "";

  // Set the sorted and filtered search parameters
  urlObj.search = sortedSearchParams.toString();

  return urlObj.toString();
};

/**
 *
 * @param {Element} element
 */
JourneySolutionBuilder.prototype.buildFromElement = function (element) {
  let params = JSON.parse(
    element.getAttribute(DataAttributesConstant.favoritesParams)
  );

  this.setJourney(params.data.from, params.data.to, params.data.linkHref, params.data.fromCoord, params.data.toCoord);
};
