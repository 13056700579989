import {traffic_config} from "./constants";
import {manageTrafficAutocomplete} from "../components/autocomplete";
import {displayDiscruptionsByLineEventListener} from "../components/line";
import {displayDiscruptionsByModeEventListener} from "../components/modes";
import {manageCustomSelect} from "../components/select";
import {manageNetworksFilter} from "../components/networks";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";
import {displayLinesOnMap, showLine} from "../components/map";
import { manageParamLineId } from "../components/line/line-details";

export async function initEventListeners() {
    const trafficContainer = document.querySelector(traffic_config.container);
    if (trafficContainer.childNodes.length == 0) {
        document.body.addEventListener('traffic_container_displayed', function () {
            document.title = Translator.trans('traffic.title');
            Kisio.section_active = 'traffic';
            getCurrentDisruptions(trafficContainer);
            manageNetworkPopupEventListeners();
            manageMap();

            try {
                const contentHeader = document.querySelector(traffic_config.disruptions_header);
                const boardTitle = document.getElementById(traffic_config.board_title);
                const contentBody = document.querySelector(traffic_config.disruptions_body);
                const trafficContent = document.querySelector(traffic_config.traffic_content);

                new SimpleBar(trafficContent);
                const headerHieght = contentHeader.offsetHeight + boardTitle.offsetHeight
                contentBody.style.height = `calc(96vh - ${headerHieght}px)`;
            } catch (e) {
                console.log(e);
            }

            document.querySelectorAll('.traffic-home__modes label').forEach(el => {
                el.addEventListener('keyup', (event) => {
                    if (event.keyCode == 32 || event.keyCode == 13) {
                        event.target.click();
                    }
                });
            });

            document.querySelectorAll('.disruption__lines-item').forEach(el => {
                el.addEventListener('keyup', (event) => {
                    if (event.keyCode == 32 || event.keyCode == 13) {
                        event.target.click();
                    }
                });
            });

        });
    }
}

function manageMap() {
    if(isLineShowned() === true) {
        let lineId = getShownedLineElements().data('line-id');
        return showLine(lineId);
    }

    if(Kisio.traffic_all_line_activated == true) {
        displayLinesOnMap();
    }
}

function isLineShowned() {
    return getShownedLineElements().length > 0;
}

function getShownedLineElements() {
    return JKisio('.disruption__lines-item-container').not('.gl-hide');
}

function manageNetworkPopupEventListeners() {
    const networkPopups = document.querySelectorAll(traffic_config.network_disruption);

    [...networkPopups].forEach(function (networkPopup) {
        const messageEl = networkPopup.querySelector('.disruption__message');
        const messageStyle = getComputedStyle(messageEl);
        if (parseInt(messageStyle.lineHeight.replace('px', '') ) * 2 < messageEl.offsetHeight) {
            networkPopup.classList.add('hide-txt');
            const showMore = networkPopup.querySelector(traffic_config.network_content_show);
            showMore.classList.remove('gl-hide');
            showMore.addEventListener('click', function () {
                if (networkPopup.classList.contains('hide-txt')) {
                    networkPopup.classList.remove('hide-txt');
                    return;
                }
                networkPopup.classList.add('hide-txt');
            });
        }
    });
}

function getCurrentDisruptions(element) {
    let result = null;

    // Ajax Call
    JKisio.ajax({
        url: Routing.generate(
            'traffic_result',
            { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '',}
        ),
        async: false,
        success: function (data) {
            if (data.code == 200) {
                element.innerHTML = data.response;

                manageTrafficAutocomplete();

                displayDiscruptionsByLineEventListener();
                displayDiscruptionsByModeEventListener();

                manageCustomSelect();
                manageNetworksFilter();

                setTimeout(function() {
                    manageParamLineId();
                }, 500);
            }
        },
        error: function (error) {
            console.log('call api error (traffic)');
        }
    });

    return result;
}