export function geolocateMapButton(L) {
    if (JKisio('.leaflet-control-locate')?.length == 0) {
        L.control.locate({
            position: 'topright',
            strings: {
                title: Translator.trans('canaltp_pnpcore.geolocate.button_map')
            },
            drawCircle: false
        }).addTo(window.leaflet.map);
    }
}
