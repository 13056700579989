export function NavitiaObject(index, { section, list } = {}) {
  this.internal = {};
  this.index = index;
  this.internal[index] = {
    section: section,
    list: list || [],
  };
}

NavitiaObject.prototype.getList = function (index) {
  return this.internal[index].list;
};

NavitiaObject.prototype.getIndex = function () {
  return this.index;
};

NavitiaObject.prototype.setList = function (index, list) {
  this.internal[index].list = list;
};

NavitiaObject.prototype.getSection = function (index) {
  return this.internal[index].section;
};

NavitiaObject.prototype.setSection = function (index, section) {
  this.internal[index].section = section;
};

NavitiaObject.prototype.getInternal = function () {
  return this.internal;
};
