import { manageButtonDepartureArrival } from ".";

export function ButtonDepartureArrival({
    lat,
    lng,
    address,
    launchSearch = false
} = {}) {
    this.container = document.createElement('div');
    this.container.setAttribute('class', 'from-to-buttons-container');

    // Add btn 
    this.container.appendChild(new ButtonDeparture({
        lat,
        lng,
        address,
        launchSearch
    }).build());

    this.container.appendChild(new ButtonArrival({
        lat,
        lng,
        address,
        launchSearch
    }).build());
}

ButtonDepartureArrival.prototype.click = function (event) {
    let map = window.leaflet.map;
    manageButtonDepartureArrival(event.currentTarget, map);
}

ButtonDepartureArrival.prototype.buildHtml = function () {
    jKisio(document);
    jKisio(document)
        .off("click", ".from-to-buttons-container button")
        .on("click", ".from-to-buttons-container button", this.click);
    return this.container.outerHTML;
};

ButtonDepartureArrival.prototype.build = function () {
    this.container.querySelector(".from-to-buttons-container button").addEventListener('click', this.click);

    return this.container;
};


function ButtonDeparture({
    lat,
    lng,
    address,
    launchSearch
} = {}) {
    this.el = document.createElement('button');
    this.el.setAttribute('class', 'ikisio ikisio-from');
    this.el.setAttribute('data-fieldtype', 'from');

    this.el.setAttribute('data-lat', lat);
    this.el.setAttribute('data-lng', lng);
    this.el.setAttribute('data-address', address);
    this.el.setAttribute('data-search', launchSearch);

    this.content = document.createElement('span');
    this.content.innerText = Translator.trans('popup.links.from.title');

    this.el.appendChild(this.content);    
}

ButtonDeparture.prototype.build = function () {
    return this.el;
};

function ButtonArrival({
    lat,
    lng,
    address,
    launchSearch
} = {}) {
    this.el = document.createElement('button');
    this.el.setAttribute('class', 'ikisio ikisio-to');
    this.el.setAttribute('data-fieldtype', 'to');

    this.el.setAttribute('data-lat', lat);
    this.el.setAttribute('data-lng', lng);
    this.el.setAttribute('data-address', address);
    this.el.setAttribute('data-search', launchSearch);

    this.content = document.createElement('span');
    this.content.innerText = Translator.trans('popup.links.to.title');

    this.el.appendChild(this.content);
}

ButtonArrival.prototype.build = function () {
    return this.el;
};