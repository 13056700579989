import {line_config} from "./constants";
import {lineEventListeners, manageDisruptionLineDetails} from "./line-details";

export function displayDiscruptionsByLineEventListener() {
    document.body.addEventListener(line_config.selected_line, function(e) {
        displayLines(e.detail.id);
        checkNotFoundDisruption(line_config.active_disruptions);
        checkNotFoundDisruption(line_config.future_disruptions);
        document.body.dispatchEvent(new CustomEvent(line_config.reset_modes));
        document.body.dispatchEvent(new CustomEvent(line_config.reset_networks_list));
        document.body.dispatchEvent(new CustomEvent(line_config.show_disruption_by_network_id, {detail: e.detail}));
    });

    displayAllLinesEventListener();

    document.body.addEventListener('check_empty_section', function () {
        removeNotFoundDisruptionMessage();
        checkNotFoundDisruption(line_config.active_disruptions);
        checkNotFoundDisruption(line_config.future_disruptions);
    });

    manageDisruptionLineDetails();
    observeChangeOnDisplayedLine();
}

export function dispatchDisplayedLinesEvent() {
    let linesId = [];
    const modes = document.body.querySelectorAll(line_config.disruption_lines_mode);
    modes.forEach(function (mode) {
        if (!mode.classList.contains('gl-hide')) {
            const lines = mode.querySelectorAll(`${line_config.lines_item}[data-line-id]`);
            lines.forEach(function (line) {
                if (!line.classList.contains('gl-hide') && !linesId.includes(line.dataset.lineId)) {
                    linesId.push(line.dataset.lineId);
                }
            });
        }
    });

    document.body.dispatchEvent(new CustomEvent(line_config.display_layers_on_map_event, {
        detail: {
            linesId: linesId,
            section: "traffic",
            layerGroupId: "trafficLayers"
        }
    }));

    return linesId;
}

function displayLines(lineId = null) {
    if (!lineId) {
        removeNotFoundDisruptionMessage();
    }

    document.querySelectorAll(line_config.disruption_lines_mode).forEach(function (mode) {
        let disruptedLine = false;
        mode.querySelectorAll(line_config.lines_item).forEach(function (line) {
            if (!lineId) {
                line.classList.remove('gl-hide');
                return;
            }

            if (lineId === line.dataset.lineId) {
                disruptedLine = true;

                return;
            }
            line.classList.add('gl-hide');
        });

        mode.classList.remove('gl-hide');
        if (!disruptedLine && lineId) {
            mode.classList.add('gl-hide');
        }
    });
}

function displayAllLinesEventListener() {
    document.body.addEventListener(line_config.input_value_is_empty, function () {
        displayAllLines();
        document.body.dispatchEvent(new CustomEvent(line_config.show_all_network_disruptions));
    });

    document.body.addEventListener(line_config.input_cleared, function () {
        displayAllLines();
        document.body.dispatchEvent(new CustomEvent(line_config.reset_modes));
        document.body.dispatchEvent(new CustomEvent(line_config.reset_networks_list));
        document.body.dispatchEvent(new CustomEvent(line_config.show_all_network_disruptions));
    });
}

function displayAllLines() {
    displayLines();
    removeNotFoundDisruptionMessage();
}

function checkNotFoundDisruption(sectionType) {
    let disruptionModeNotFound = true;
    const sectionTypeEl = document.querySelector(sectionType);
    sectionTypeEl?.querySelectorAll(line_config.disruption_lines_mode)?.forEach(function (mode) {
        if (!mode.classList.contains('gl-hide'))  {
            disruptionModeNotFound = false;
        }
    });

    if (disruptionModeNotFound && !sectionTypeEl?.querySelector('.' + line_config.disruption_no_content)) {
        displayNotFoundDisruptionMessage(sectionTypeEl, sectionType);
    }
}

function removeNotFoundDisruptionMessage() {
    document.querySelectorAll('.' + line_config.disruption_no_content).forEach(function (content) {
        content.remove();
    });
}

function displayNotFoundDisruptionMessage(sectionTypeEl, sectionType) {
    if (!sectionTypeEl) {
        return;
    }

    let noDisruption = document.createElement("div");
    noDisruption.classList.add(line_config.disruption_no_content);
    noDisruption.setAttribute('data-section-type', 'sectionType');
    noDisruption.innerText = Translator.trans('traffic.network.no_disruption_type' + sectionType);

    sectionTypeEl.appendChild(noDisruption);
}

function observeChangeOnDisplayedLine() {
    const activeDisruptions = document.querySelector(line_config.active_disruptions);
    if (!activeDisruptions) {
        return;
    }

    const observer = new MutationObserver(function (mutations) {
        let isMuted = false;
        mutations.forEach(function (mutation) {
            if (!isMuted) {
                isMuted = true;
                dispatchDisplayedLinesEvent();
            }
        });
    })

    observer.observe(activeDisruptions, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true
    });
}
