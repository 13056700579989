export function LocalStorageManager() {}

const LOCAL_STORAGE_KEY = "bookmarks_cache";

LocalStorageManager.prototype.getCache = function () {
  const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cachedData) {
    return JSON.parse(cachedData);
  }
  return {
    bookmarks: [],
    lastUpdated: 0,
  };
};

LocalStorageManager.prototype.setCache = function (bookmarks) {
  const cache = {
    bookmarks: bookmarks,
    lastUpdated: Date.now(),
  };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cache));
};

LocalStorageManager.prototype.invalidateCache = function () {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};
