import {traffic_config} from "../../core/constants";

const networks_config = {
    event_name: 'traffic-network-filter',
    lines_item: '.disruption__lines-item',
    reset_list: 'reset_traffic_network_list',
    container: '.disruption__network-container',
    show_disruption_by_network_id: 'show_disruption_by_network_id',
    show_all_network_disruptions: 'show_all_network_disruptions'
}

export function manageNetworksFilter() {
    document.body.addEventListener(networks_config.event_name, function (e) {
        document.body.dispatchEvent(new CustomEvent('display_seleced_modes'));
        // display all lines
        document.body.dispatchEvent(
            new CustomEvent('empty_lines_by_mode', {detail: {type: 'display-all'}})
        );

        // display all networks
        displayNetworkDisruptions("default");

        // filter by network id
        displayLinesByNetworkId(e.detail.value);
        displayNetworkDisruptions(e.detail.value);

        // dispatch event to check if empty lines
        document.body.dispatchEvent(new CustomEvent('empty_lines_by_mode'));
        document.body.dispatchEvent(new CustomEvent('check_empty_section'));
    });
    displayDisruptionsNetworkEventListener();
    resetNetworkEventListener();
}

function displayLinesByNetworkId(networkId) {
    const allLines = document.querySelectorAll(networks_config.lines_item);
    allLines?.forEach(function (line) {
        if (line.classList.contains('gl-hide')) {
            line.classList.remove('gl-hide');
            delete line.dataset.selecedNetwork;
        }

        if (networkId === "default") {
            return;
        }

        if (line.dataset.networkId !== networkId) {
            line.classList.add('gl-hide');

            return;
        }

        line.dataset.selecedNetwork = networkId;
    });
}

function resetNetworkEventListener() {
    document.body.addEventListener(networks_config.reset_list, function () {
        const inputEl = document.querySelector('input[name="item"]');
        if (inputEl) {
            inputEl.checked = true;
        }
    });
}

function displayNetworkDisruptions(networkId) {
    document.querySelectorAll(networks_config.container)?.forEach(function (disruption) {
        if (networkId === "default") {
            disruption.classList.remove('gl-hide');
            return;
        }

        if (networkId && !disruption.dataset?.networkIds.includes(networkId)) {
            disruption.classList.add("gl-hide");
        }
    });

    // event for empty section and diruptions
    document.body.dispatchEvent(new CustomEvent('check_empty_section'));
}

function displayDisruptionsNetworkEventListener() {
    document.body.addEventListener(networks_config.show_disruption_by_network_id, function (e) {
        if (e.detail?.network_id) {
            displayNetworkDisruptions(e.detail.network_id);
        }
    });

    document.body.addEventListener(networks_config.show_all_network_disruptions, function () {
        displayNetworkDisruptions('default');
    });
}
