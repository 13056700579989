const select_config = {
    select_class: '.ctp-select-custom'
}

export function manageCustomSelect() {
    document.querySelectorAll(select_config.select_class)?.forEach(function (select) {
        openCloseListItemsListener(select);
    });
}

function openCloseListItemsListener(select) {
    select.querySelectorAll('input').forEach(function (input) {
        input.addEventListener('click', function() {
            document.body.dispatchEvent(new CustomEvent(select.dataset.eventName, {
                detail: {
                    value: input.id
                }
            }));
            if (select.hasAttribute("open")) {
                select.removeAttribute("open");
                return;
            }
        
            select.setAttribute("open", "");
        });
    });
    select.querySelectorAll('li').forEach(function (li) {
        li.addEventListener('keyup', (event) => {
            if (event.keyCode == 32 || event.keyCode == 13) {
                var id = li.querySelector('label').getAttribute('for');
                select.querySelector(`#${id.replace(/:/g, '\\:')}`).click();
            }
        })
    })
}