export async function callApiLinesById(lineId) {
    let result;
    try {
        result = await JKisio.ajax({
            url: Routing.generate('traffic_get_lines_by_id', {
                id: lineId,
                type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : ''
            }),
            async: true,
            dataType: 'json',
            success: function (data) {
                return data;
            }
        });

        return result;
    } catch (e) {
        console.error(e)
    }
}

export async function callApiStopPointsByLineId(lineId) {
    let result;
    try {
        result = await JKisio.ajax({
            url: Routing.generate('r_public_transport_objects_filtered', {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'lines',
                public_transport_object_id: lineId,
                action: 'stop_points',
                count: 100
            }),
            async: true,
            dataType: 'json',
            success: function (data) {
                return data;
            },
            error: function (data) {
                return data;
            }
        });

        return result;
    } catch (e) {
        console.error(e)
    }
}
