const desktopBreakPoint = 600;

export const manageButtonDepartureArrival = (el, map) => {
    let fieldType = el.dataset.fieldtype;
    let lat = el.dataset.lat;
    let lng = el.dataset.lng;
    let address = el.dataset.address;
    let search = el.dataset.search;
    
    // Close active board
    document.body.dispatchEvent(new CustomEvent(`${Kisio.section_active}_close_section`));
    // Open journey
    if (Kisio.section_active !== 'journey') {
        document.body.dispatchEvent(new CustomEvent("display_selected_section", { detail: 'journey' }));
    }

    let params = {
        fieldType: fieldType,
        address: address,
        latlng: {
            lat,
            lng
        },
    };

    manageClick(params, map);

    if (search == 'true') {
        launchSearch(params, map);
        return;
    }

    window.dispatchEvent(new CustomEvent("journey_input_address_changed"));
}

const manageClick = function (params, map) {
    map.closePopup();
    if (JKisio('#result_content').is(':hidden')) {
        JKisio('#search_from_autocomplete, #search_to_autocomplete').val('');
        JKisio('#result_content').show();
    }
    if (JKisio('#schedule_title').length > 0 && JKisio('#journey_title').length > 0) {
        JKisio('#schedule_title').removeClass('selected');
        JKisio('#schedule_content').hide();
        JKisio('#journey_title').addClass('selected');
        JKisio('#journey_content, #board_title_container').show();
        if (JKisio('#proximity_content').is(':visible')) {
            JKisio('#proximity_content').hide();
            JKisio('#board_footer').show();
        }
    }

    manageGeoMarkers(params.fieldType, params.address, params.latlng, map);

    if (JKisio('.kisio-result-bloc').length > 0 || JKisio('#kisio-full-details').length > 0) {
        JKisio('.kisio-result-summary, .kisio-result-toolbox').empty();
        JKisio('.kisio-result, .journey-search-form, .result-back-button').hide();
        JKisio('#kisio-full-details').removeAttr('class').empty();
        JKisio('#journey-form-new-search').show();
        JKisio('#journey-search-board-modes').show();
        JKisio('.journey-line-container, .via-container').hide();
        map.eachLayer(function (layer) {
            if (typeof layer._path !== 'undefined') {
                map.removeLayer(layer);
            }
        });
        JKisio('.kisio-result-bloc, .journey-search-form').show();
    }
};

const manageGeoMarkers = (fieldType, address, latlng, currentMap = null) => {
    window.leaflet = window.leaflet || {};
    window.leaflet.markers = window.leaflet.markers || {};
    currentMap = currentMap || window.leaflet.map;

    if (JKisio('#mapid').is(':visible')) {
        if (!currentMap) {
            return;
        }

        if (window?.leaflet?.markers?.from && window?.leaflet?.markers?.from?.options?.title === fieldType) currentMap.removeLayer(window.leaflet.markers.from);
        if (window?.leaflet?.markers?.to && window?.leaflet?.markers?.to?.options?.title === fieldType) currentMap.removeLayer(window.leaflet.markers.to);
        if (fieldType === 'from') {
            window.leaflet.markers.from = window.Kisio.MapPopup.getCustomerMarker("from", latlng);
            window.leaflet.markers.from.addTo(currentMap);
            window.leaflet.markers.number = window.leaflet.markers.number ?? 0 + 1;
        }
        if (fieldType === 'to') {
            window.leaflet.markers.to = window.Kisio.MapPopup.getCustomerMarker("to", latlng);
            window.leaflet.markers.to.addTo(currentMap);
            window.leaflet.markers.number = window.leaflet.markers.number ?? 0 + 1;
        }

        if (address)
            JKisio('#search_' + fieldType + '_autocomplete').val(address).trigger('change');

        JKisio('#search_' + fieldType + '_autocomplete-hidden').val(`${latlng.lng};${latlng.lat}`).trigger('change');
        
        if (window?.leaflet?.markers?.from && window?.leaflet?.markers?.to) {
            currentMap.fitBounds([window.leaflet.markers.from._latlng, window.leaflet.markers.to._latlng], mapPopupConfig());
        } else {
            currentMap.fitBounds([latlng], mapPopupConfig());
        }
        if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
            currentMap.setZoom(12);
        }
    }
};


const mapPopupConfig = () => {
    const boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;

    if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
        return {
            paddingTopLeft: L.point(boardWidth - 250, -250),
            paddingBottomRight: L.point(0, 0),
            maxZoom: 14
        }
    }

    return {
        paddingTopLeft: L.point(boardWidth + 30, 32),
        paddingBottomRight: L.point(0, 32)
    }
};

const launchSearch = (params) => {
    navigator.permissions.query({name:'geolocation'}).then(function(result) {
        let coords = "";
        if (result.state == 'granted') {
            getPosition()
            .then((position) => {
                coords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                getAddress(coords.lng+';'+coords.lat, params);
            })
            .catch((err) => {
                console.error(err.message);
            });
            return;
        }
        coords = {
            lat: Kisio.map_config.latitude,
            lng: Kisio.map_config.longitude
        };
        getAddress(coords.lng+';'+coords.lat, params);
        return;        
    });
    console.error("Search not possible");
}

const getAddress = (coords, params) => {
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '';
    JKisio.ajax({
        url: Routing.generate('coords', { coords: coords, type_product: type_product }),
        dataType: "json",
        async: false,
        success: function(result) {
            if (result.hasOwnProperty('display_name')) {

                manageGeoMarkers(
                    params.fieldType == "from" ? "to" : "from",
                    result.display_name,
                    {
                        lat: result.coord.lat,
                        lng: result.coord.lon,
                    }
                );

                JKisio('#journey-target-form')?.submit();
            }
        }
    })
}

function getPosition() { 
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
   );     
}