import { EventConstant } from "../../events/constants";
import { DataAttributesConstant } from "./constants";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";

export function BookmarkStarElementBuilder({
  id,
  className = "fav-star fav-star-add fav-stop_area_lines_direction",
  manager = "cookies",
  typeSection = "schedule",
  type = "stop_area_lines_direction",
  tabIndex = "0",
  params = "",
  resourceId,
} = {}) {
  this.bookmark = document.createElement("div");

  // no bookmark if disabled or no user consent
  if (Kisio.user_consent != 1 || Kisio.enable_favorites != 1) {
    this.bookmark.style.display = "none";
  }

  // create client and get bookmark. NB : for api bookmark, make sur a cache is available
  let client = new BookmarkClientFactory().createClient();
  let bookmarkModel = client.getOneSync(params.id);
  let title;
  
  if (bookmarkModel !== null) {
    title = Translator.trans('bookmark.menu.button.delete');
  } else {
    title = Translator.trans('bookmark.menu.button.add');
  }


  setAttributes(this.bookmark, {
    class: className,
    [DataAttributesConstant.favoritesManager]: manager,
    [DataAttributesConstant.favoritesTypeSection]: typeSection,
    [DataAttributesConstant.favoritesType]: type,
    resourceId: resourceId,
    tabindex: tabIndex,
    title: title,
    "aria-label":  title,
    "role": "link",
    [DataAttributesConstant.favoritesParams]: JSON.stringify(params),
  });

  if(id) {
    setAttributes(this.bookmark, {id: id});
  }

  if (bookmarkModel !== null) {
    this.bookmark.classList.add("fav-star-del");
  }

  const em = document.createElement("em");
  setAttributes(em, {
    class: "ikisio ikisio-favorites",
  });
  this.bookmark.appendChild(em);

  // work only on eleent addded manually
  this.bookmark.addEventListener("click", this.click);
  this.bookmark.addEventListener("keyup", this.enter);
}

BookmarkStarElementBuilder.prototype.click = function (clickEvent) {
  let eventName = clickEvent.currentTarget.classList.contains("fav-star-del")
    ? EventConstant.delete_bookmark_event_name
    : EventConstant.add_bookmark_event_name;
  let customEvent = new CustomEvent(eventName, {
    detail: { element: clickEvent.currentTarget },
  });
  document.dispatchEvent(customEvent);
  if (clickEvent.currentTarget.classList.contains("fav-star-del")) {
    clickEvent.currentTarget.classList.remove("fav-star-del");
    return;
  }
  clickEvent.currentTarget.classList.add("fav-star-del");
};

BookmarkStarElementBuilder.prototype.enter = function (enterEvent) {
  let target = enterEvent.target;
    if (enterEvent.keyCode == 32 || enterEvent.keyCode == 13) {
      enterEvent.stopPropagation();
        JKisio(target).trigger('click');
    }
}

BookmarkStarElementBuilder.prototype.setId = function (id) {
  this.bookmark.setAttribute("id", id);
  return this;
};

BookmarkStarElementBuilder.prototype.setClass = function (className) {
  this.bookmark.setAttribute("class", className);
  return this;
};

BookmarkStarElementBuilder.prototype.setManager = function (manager) {
  this.bookmark.setAttribute(DataAttributesConstant.favoritesManager, manager);
  return this;
};

BookmarkStarElementBuilder.prototype.setTypeSection = function (typeSection) {
  this.bookmark.setAttribute(
    DataAttributesConstant.favoritesTypeSection,
    typeSection
  );
  return this;
};

BookmarkStarElementBuilder.prototype.setType = function (type) {
  this.bookmark.setAttribute(DataAttributesConstant.favoritesType, type);
  return this;
};

BookmarkStarElementBuilder.prototype.setTabIndex = function (tabIndex) {
  this.bookmark.setAttribute("tabindex", tabIndex);
  return this;
};

BookmarkStarElementBuilder.prototype.setTitle = function (title) {
  this.bookmark.setAttribute("title", title);
  this.bookmark.setAttribute("aria-label", title);
  return this;
};

BookmarkStarElementBuilder.prototype.setParams = function (params) {
  this.bookmark.setAttribute(DataAttributesConstant.favoritesParams, params);
  return this;
};

BookmarkStarElementBuilder.prototype.build = function () {
  return this.bookmark;
};

BookmarkStarElementBuilder.prototype.buildHtml = function () {
  this.bookmark.classList.add("fav-star-html");
  jKisio(document)
    .off("click", ".fav-star-html")
    .on("click", ".fav-star-html", this.click);
  jKisio(document)
    .off("keyup", ".fav-star-html")
    .on("keyup", ".fav-star-html", this.enter);
  return this.bookmark.outerHTML;
};

function setAttributes(element, attributes) {
  Object.keys(attributes).forEach((attr) => {
    element.setAttribute(attr, attributes[attr]);
  });
}
