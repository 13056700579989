export function executeIfMapExist(callback) {
    if(isMapExist() === true) {
        return callback();
    }
}

/**
 * Try to find a way to know if map has been set before
 * @returns {boolean}
 */
function isMapExist() {
    return window.leaflet.map !== null && typeof  window.leaflet.map != 'undefined';
}