import { NavitiaObject } from "./navitia-object";
import { JourneySolutionBuilder } from "./journey-solution";
import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export function StopAreas() {
  this.internal = {
    id: null,
    type: "stop_area_lines_direction",
    data: {
      id: null,
      stopAreaId: null,
      lineId: null,
      mode: null,
      code: null,
      codeBgColor: null,
      codeColor: null,
      networkId: null,
      name: null,
      latitude: null,
      longitude: null,
      directionType: null,
    },
  };
}

// stop_area_lines_direction

/**
 *
 * @param stopAreaId
 * @param lineId
 * @param mode
 * @param code
 * @param codeBgColor
 * @param codeColor
 * @param networkId
 * @param name
 * @param latitude
 * @param longitude
 * @param directionType
 * @returns {StopAreas}
 */
StopAreas.prototype.setStopArea = function (
  stopAreaId,
  lineId,
  mode,
  code,
  codeBgColor,
  codeColor,
  networkId,
  name,
  latitude,
  longitude,
  directionType = "outbound"
) {
  this.internal.id = stopAreaId + "_" + lineId;
  this.internal.data.id = stopAreaId;
  this.internal.data.stopAreaId = stopAreaId;
  this.internal.data.lineId = lineId;
  this.internal.data.mode = mode.toLowerCase();
  this.internal.data.code = code.toLowerCase();
  this.internal.data.codeBgColor = codeBgColor;
  this.internal.data.codeColor = codeColor;
  this.internal.data.networkId = networkId;
  this.internal.data.name = name;
  this.internal.data.latitude = latitude;
  this.internal.data.longitude = longitude;
  this.internal.data.directionType = directionType;
  return this;
};

StopAreas.prototype.getNavitiaObject = function () {
  return this.internal;
};

/**
 * @param {Element} element
 */
StopAreas.prototype.buildFromElement = function (element) {
  let params = JSON.parse(
    element.getAttribute(DataAttributesConstant.favoritesParams)
  );

  this.setStopArea(
    params.data.stopAreaId,
    params.data.lineId,
    params.data.mode,
    params.data.code,
    params.data.codeBgColor,
    params.data.codeColor,
    params.data.networkId,
    params.data.name,
    params.data.latitude,
    params.data.longitude,
    params.data.directionType ?? "outbound"
  );
};
