const networks_type = 'networks';

/**
 * @param {string} type picto type line or network
 * @param {string} id navitia line id
 * @returns {boolean}
 */
export function hasLogo(type, id)
{
    if (!Kisio[type]) {
        return false;
    }

    const configList = Array.isArray(Kisio[type]) ? Kisio[type] : Object.entries(Kisio[type]);
    let picto = configList.filter((object, index) => {
        const keys = Object.keys(object);
        const firstKey = keys[0];
        if (type === networks_type && object[firstKey] === id) {
            if (!Kisio[type][id]['img']) {
                return false;
            }

            return true;
        }

        return firstKey === id;
    });

    return picto.length > 0;
}

/**
 * @param {string} type picto type line or network
 * @param {string} id navitia line id
 * @returns {string}
 */
export function getLogoSrc(type, id)
{
    if (!Kisio[type]) {
        return '';
    }

    let src;
    const configList = Array.isArray(Kisio[type]) ? Kisio[type] : Object.entries(Kisio[type]);
    configList.filter((object, index) => {
        const keys = Object.keys(object);
        const firstKey = keys[0];

        if (type === networks_type && object[firstKey] === id) {
            src = Kisio[type][id]['img'];
            return;
        }

        if(firstKey === id) {
            src = object[firstKey];
        }
    });

    return src;
}

/**
 * @param {string} type picto type line or network
 * @param {string} id navitia line id
 * @param {string} fallbackHtml html fallback if no line logo
 * @param {object} params
 * @returns {string}
 */
export function generateLogoFromConfig(type, id, fallbackHtml, params = {}) {
    const defaultParams = {
        alt: "",
        classname: "",
        dataset: {},
        style: "",
    };

    // Merge default params with provided params
    const mergedParams = { ...defaultParams, ...params };

    if (true === hasLogo(type, id)) {
        const { alt, classname, dataset, style } = mergedParams;
        const datasetAttributes = Object.entries(dataset).map(([key, value]) => `data-${key}="${value}"`).join(" ");
        return `<img alt="${alt}" class="${type}-logo ${type}-logo-${id} ${classname}" src="${getLogoSrc(type, id)}" style="${style}" ${datasetAttributes}/>`;
    }

    return fallbackHtml;
}