import { Bookmark } from "../model/bookmark";
import $ from "jquery";
if (typeof jKisio === "undefined") {
  window.jKisio = $;
}

export function BookmarkClient() {}

BookmarkClient.prototype.get = function () {
  return new Promise((resolve, reject) => {
    jKisio.ajax({
      url: "/bookmarks/",
      method: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        const bookmarks = data.map((item) => {
          const bookmark = new Bookmark();
          bookmark.setId(item.id).setType(item.type).setData(item.data);
          return bookmark;
        });
        resolve(bookmarks);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        reject({ jqXHR, textStatus, errorThrown });
      },
    });
  });
};

BookmarkClient.prototype.getOne = function (id) {
  return new Promise((resolve, reject) => {
    jKisio.ajax({
      url: `/bookmarks/${id}`,
      method: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        const bookmark = new Bookmark();
        bookmark.setId(data.id).setType(data.type).setData(data.data);
        resolve(bookmark);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        reject({ jqXHR, textStatus, errorThrown });
      },
    });
  });
};

BookmarkClient.prototype.update = function (id, bookmark) {
  return new Promise((resolve, reject) => {
    jKisio.ajax({
      url: `/bookmarks/${id}`,
      method: "PUT",
      data: bookmark.getObject(),
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        const updatedBookmark = new Bookmark();
        updatedBookmark.setId(data.id).setType(data.type).setData(data.data);
        resolve(updatedBookmark);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        reject({ jqXHR, textStatus, errorThrown });
      },
    });
  });
};

BookmarkClient.prototype.delete = function (id) {
  return new Promise((resolve, reject) => {
    jKisio.ajax({
      url: `/bookmarks/${id}`,
      method: "DELETE",
      xhrFields: {
        withCredentials: true,
      },
      success: () => {
        resolve();
      },
      error: (jqXHR, textStatus, errorThrown) => {
        reject({ jqXHR, textStatus, errorThrown });
      },
    });
  });
};

BookmarkClient.prototype.patch = function (id, data) {
  return new Promise((resolve, reject) => {
    jKisio.ajax({
      url: `/bookmarks/${id}`,
      method: "PATCH",
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        const patchedBookmark = new Bookmark();
        patchedBookmark.setId(data.id).setType(data.type).setData(data.data);
        resolve(patchedBookmark);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        reject({ jqXHR, textStatus, errorThrown });
      },
    });
  });
};
