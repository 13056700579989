import {callApiLinesById, callApiStopPointsByLineId} from "./core";

import {generateLogoFromConfig} from "pnp_core_bundle/modules/utils/logoManagement";
import {mapPopupConfig} from "pnp_core_bundle/plugnplay/leaf-core";
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

const lines_config = {
    item: '.disruption__lines-item',
    display_layers_on_map_event: 'display_layers_on_map_event',
    display_all_lines_on_map_event: 'display_all_lines_on_map_event',
    layer_section: 'traffic-home',
    loader: 'loading-box'
}

export function displayLinesOnMap() {

    executeIfMapExist(() => {displayLines();});

    document.body.addEventListener(lines_config.display_all_lines_on_map_event, function () {
        displayAllLines();
    });
}

async function displayLines() {
    if (displayAllLines()) {
        return;
    }

    const linesId = [];
    const linesItem = document.querySelectorAll(lines_config.item);

    // display loader
    document.getElementById(lines_config.loader).style.visibility = 'visible';
    await Promise.all([...linesItem]?.map(async function (line) {
        if (line.dataset?.lineId && !linesId.includes(line.dataset.lineId)) {
            linesId.push(line.dataset.lineId);

            Kisio.layerGroup.traffic[line.dataset.lineId] = new L.layerGroup();

            await callApiLinesById(line.dataset.lineId).then(function (data) {
                const lineStyle = {
                    color: "#" + data.color,
                    weight: 5,
                    opacity: 1
                };
                const options = {
                    style: lineStyle,
                    line_id: data.id,
                    physical_mode: data.physical_modes[0].id,
                    network_id: data.network.id,
                    layer_section: [lines_config.layer_section]
                };

                if (data.geojson && data.geojson.coordinates?.length !== 0) {
                    let lineGeoJSON = L.geoJSON(data.geojson, options)
                        .on('mouseover', function (e) {
                            if (e.layer.options.opacity !== 0) {
                                e.target.bindPopup(generateLinePopup(data), {closeButton: false}).openPopup([e.latlng.lat, e.latlng.lng]);
                            }
                        })
                        .on('mouseout', function (e) {
                            e.target.closePopup();
                        });
                    displayStopPointByLineId(line.dataset.lineId, lineStyle.color, data);

                    Kisio.layerGroup.traffic[line.dataset.lineId].addLayer(lineGeoJSON);

                    if (Kisio.section_active == "traffic") {
                        Kisio.layerGroup.traffic[line.dataset.lineId].addTo(window.leaflet.map);
                    }
                }
            });
        }
    }));

    // hide loader
    document.getElementById(lines_config.loader).style.visibility = 'hidden';

    window.leaflet.map.on('zoomend', function() {
        Object.values(window.leaflet.map._layers).forEach(function(layer) {
            if (layer instanceof L.CircleMarker && layer.options.layer_section?.includes(lines_config.layer_section)) {
                const weight = window.leaflet.map.getZoom() > 11 ? 3 : 0.5;
                layer.setStyle({
                    weight: weight
                });
            }
        });
    });
}

function displayStopPointByLineId(lineId, lineColor, line) {
    if (!lineId) {
        return;
    }

    callApiStopPointsByLineId(lineId).then(function (data) {
        const stopPoints = data.stop_points;

        if (typeof stopPoints !== "undefined") {
            const group = L.featureGroup();
            const stopPointList = [];
            stopPoints.forEach(function (stopPoint) {
                if (!stopPoint.coord || !stopPoint.coord.lat || !stopPoint.coord.lon) {
                    return;
                }

                if (stopPointList.includes(stopPoint.name))  {
                    return;
                }

                stopPointList.push(stopPoint.name);
                L.circleMarker(
                    [stopPoint.coord.lat, stopPoint.coord.lon], {
                        radius: 5,
                        width: 0.1,
                        color: lineColor,
                        fillOpacity: 1,
                        weight: 1,
                        line_id: lineId,
                        stop_point: stopPoint,
                        layer_section: [lines_config.layer_section],
                        group: 'marcker'
                    }
                ).addTo(group);
            });

            group
                .on('mouseover', function (e) {
                    if (e.layer.options.opacity !== 0) {
                        e.target.bindPopup(generateStopAreaPopup(line, e.layer.options.stop_point), {closeButton: false}).openPopup([e.latlng.lat, e.latlng.lng]);
                    }
                })
                .on('mouseout', function (e) {
                    e.target.closePopup();
                });

            // if (layer._bounds) {
            //     window.leaflet.map.fitBounds(layer.getBounds(), mapPopupConfig());
            // }
            group.options = lineId

            Kisio.layerGroup.traffic[lineId].addLayer(group);
            return;

        } else {
            console.log("No data for stop points available");
        }
    });
}

function displayAllLines() {
    let cachedLayers = false;

    if (Object.keys(Kisio.layerGroup.traffic).length) {
        document.body.dispatchEvent(new CustomEvent("display_layers_section_event", {detail: {section: "traffic"}}));
        cachedLayers = true;
    }

    if (cachedLayers) {
        window.leaflet.map.flyTo([Kisio.map_config.latitude,Kisio.map_config.longitude], window.Kisio?.map_config.zoom);
    }

    return cachedLayers;
}

function generateLinePopup(line) {
    return `<div class="traffic-map-toolip">
                ${popupHeader(line)}
                ${popupBody(line)}
            </div>`;
}

function generateStopAreaPopup(line, stopArea) {
    return `<div class="traffic-map-toolip">
                ${popupHeader(line, stopArea)}
                ${popupBody(line, false)}
            </div>`;
}

function popupHeader(line, stopArea) {
    let stopAreaHtml = '';
    if (stopArea) {
        stopAreaHtml += `<div>
                        <span>${stopArea.label}</span>
                    </div>`;
    }

    let span = generateLogoFromConfig('lines', line.id, `<span class="disruption__line-contrast" style="color:#${line.contrasted_color};background-color:#${line.color}">${line.code}</span>`, {style: 'height: 24px'} );

    return `<div class="traffic-map-toolip__header">
                <i role="presentation" class="ikisio ikisio-${line.physical_modes[0].name.toLowerCase()}"></i>
                <div class="disruption__lines-item" data-line-id="${line.id}">
                    ${span}
                </div>
                ${stopAreaHtml}
            </div>`;
}

function popupBody(line, displayRoute = true) {
    const severity = ['information', 'warning', 'disrupt'];
    let maxLevelIndex = 0;
    let disruptionsHtml = '';
    let routesHtml = '';
    const routesName = [];

    if (line.routes && displayRoute) {
        routesHtml += '<div class="traffic-map-toolip__all-routes">';
        line.routes.forEach(function (route) {
            if (!routesName.includes(route.direction.name)) {
                routesName.push(route.direction.name);
                routesHtml += `<div class="traffic-map-toolip__routes">
                                <i role="presentation" class="ikisio ikisio-arrow" aria-hidden="true"></i>
                                <span>${route.direction.name}</span>
                          </div>`;
            }
        });
        routesHtml += '</div>'
    }

    if (line.disruptions) {
        line.disruptions.forEach(function (disruption) {
            const index = severity.findIndex((element) => element === disruption.severity.level);
            disruptionsHtml += `<div class="traffic-map-toolip__discruption">
                                    <i role="presentation" class="ikisio ikisio-${disruption.severity.level}" aria-hidden="true"></i>
                                    <span class="traffic-map-toolip__discruption-title">${disruption.cause}</span>
                                </div>`;

            if (index > maxLevelIndex) {
                maxLevelIndex = index;
            }
        });
    }

    return `<div class="traffic-map-toolip__body">
                <span class="traffic-map-toolip__body-left ${severity[maxLevelIndex]}"></span>
                <div class="traffic-map-toolip__body-right">
                   ${routesHtml}
                   ${disruptionsHtml}
                </div>
            </div>`;
}

function displayLineRoutes(routes, lineColor) {
    routes?.forEach(function (route) {
        const routeName = route.direction.name;
        const routeCoord = route.direction.stop_area.coord;

        let tooltip = L.tooltip({className: 'traffic-map-toolip__terminus', direction: 'top'})
            .setLatLng([routeCoord.lat, routeCoord.lon])
            .setContent(`<span class="traffic-map-toolip__terminus-content" style="color: #000000 !important;border: 4px solid #${lineColor}">${routeName}</span>`);
            // .addTo(window.leaflet.map);

        Kisio.layerGroup.traffic.trafficLayers.addLayer(tooltip);
        
        if (Kisio.section_active == "traffic") {
            Kisio.layerGroup.traffic.trafficLayers.addTo(window.leaflet.map);
        }
    });
}



export async function showLine(lineId) {
    executeIfMapExist(async ()=>{
        document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'traffic', clean_data:false}}));

        Kisio.layerGroup.traffic[lineId] = new L.layerGroup();

        await callApiLinesById(lineId).then(function (data) {
            const lineStyle = {
                color: "#" + data.color,
                weight: 5,
                opacity: 1
            };
            const options = {
                style: lineStyle,
                line_id: data.id,
                physical_mode: data.physical_modes[0].id,
                network_id: data.network.id,
                layer_section: [lines_config.layer_section]
            };

            let lineGroup = new L.layerGroup();

            if (data.geojson && data.geojson.coordinates?.length !== 0) {
                displayStopPointByLineId(lineId, lineStyle.color, data);
                let geoJson = L.geoJSON(data.geojson, options)
                    .on('mouseover', function (e) {
                        if (e.layer.options.opacity !== 0) {
                            e.target.bindPopup(generateLinePopup(data), {closeButton: false}).openPopup([e.latlng.lat, e.latlng.lng]);
                        }
                    })
                    .on('mouseout', function (e) {
                        e.target.closePopup();
                    })
                    .addTo(lineGroup);

                // disable display routes tooltip
                // if (data.routes) {
                //     displayLineRoutes(data.routes, data.color);
                //
                window.leaflet.map.fitBounds(geoJson.getBounds(), mapPopupConfig());


                Kisio.layerGroup.traffic[lineId].addLayer(lineGroup);
                if (Kisio.section_active == "traffic") {
                    Kisio.layerGroup.traffic[lineId].addTo(window.leaflet.map);
                }
            }
        });
    });
}