import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export function StopPoint() {
  this.internal = {
    id: null,
    type: "stop_point",
    data: {
      id: null,
      mode: null,
      code: null,
      codeBgColor: null,
      codeColor: null,
      name: null,
      latitude: null,
      longitude: null,
    },
  };
}

// stop_area_lines_direction

/**
 * @param id
 * @param mode
 * @param code
 * @param codeBgColor
 * @param codeColor
 * @param name
 * @param latitude
 * @param longitude
 * @returns {StopPoint}
 */
StopPoint.prototype.setStopPoint = function (
  id,
  mode,
  code,
  codeBgColor,
  codeColor,
  name,
  latitude,
  longitude
) {
  this.internal.id = id;
  this.internal.data.mode = mode;
  this.internal.data.code = code;
  this.internal.data.codeBgColor = codeBgColor;
  this.internal.data.codeColor = codeColor;
  this.internal.data.name = name;
  this.internal.data.latitude = latitude;
  this.internal.data.longitude = longitude;
  return this;
};

StopPoint.prototype.getNavitiaObject = function () {
  return this.internal;
};

/**
 * @param {Element} element
 */
StopPoint.prototype.buildFromElement = function (element) {
  let params = JSON.parse(
    element.getAttribute(DataAttributesConstant.favoritesParams)
  );

  this.setStopPoint(
    params.id,
    params.mode,
    params.code,
    params.codeBgColor,
    params.codeColor,
    params.name,
    params.latitude,
    params.longitude
  );
};
