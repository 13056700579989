// LocalStorageBookmarkClient.js
import { BookmarkClientInterface } from "./bookmark-client-interface";
import { LocalStorageManager } from "../storage/local-storage-manager"; // Importez votre LocalStorageManager
import { Bookmark } from "../model/bookmark";

export function LocalStorageBookmarkClient() {
  this.localStorageManager = new LocalStorageManager();
}

LocalStorageBookmarkClient.prototype = Object.create(BookmarkClientInterface);

LocalStorageBookmarkClient.prototype.get = function () {
  return new Promise((resolve) => {
    const cache = this.localStorageManager.getCache();
    resolve(cache.bookmarks);
  });
};

LocalStorageBookmarkClient.prototype.getSync = function () {
  const cache = this.localStorageManager.getCache();
  return cache.bookmarks || null;
};

LocalStorageBookmarkClient.prototype.getOne = function (id) {
  return new Promise((resolve) => {
    const cache = this.localStorageManager.getCache();
    const bookmark = cache.bookmarks.find((bookmark) => bookmark.id === id);
    resolve(bookmark || null);
  });
};

LocalStorageBookmarkClient.prototype.getOneSync = function (id) {
  const cache = this.localStorageManager.getCache();
  const bookmark = cache.bookmarks.find((bookmark) => bookmark.id === id);
  return bookmark || null;
};

LocalStorageBookmarkClient.prototype.update = function (id, data) {
  return new Promise((resolve) => {
    const cache = this.localStorageManager.getCache();
    const updatedBookmarks = cache.bookmarks.map((bookmark) =>
      bookmark.id === id ? { ...bookmark, ...data } : bookmark
    );
    this.localStorageManager.setCache(updatedBookmarks);
    resolve();
  });
};

LocalStorageBookmarkClient.prototype.delete = function (id) {
  return new Promise((resolve) => {
    const cache = this.localStorageManager.getCache();
    const updatedBookmarks = cache.bookmarks.filter(
      (bookmark) => bookmark.id !== id
    );
    this.localStorageManager.setCache(updatedBookmarks);
    resolve();
  });
};

LocalStorageBookmarkClient.prototype.create = function (data) {
  return new Promise((resolve) => {
    const bookmark = new Bookmark(data);
    const cache = this.localStorageManager.getCache();

    // Vérifiez si un autre bookmark avec le même type et le même id existe
    const exists = cache.bookmarks.some(
      (bm) => bm.type === bookmark.type && bm.id === bookmark.id
    );

    if (!exists) {
      const updatedBookmarks = [...cache.bookmarks, bookmark];
      this.localStorageManager.setCache(updatedBookmarks);
      resolve(bookmark);
    } else {
      resolve(null);
    }
  });
};
