// Remove all layers from map
export function clearMap(clean_data = false) {
    let sections = Object.keys(Kisio.layerGroup);

    sections.forEach((section) => {
        Object.keys(Kisio.layerGroup[section]).forEach((layerKey) => {
            Kisio.layerGroup[section][layerKey].removeFrom(window.leaflet.map);
            
            if (clean_data == true) {
                Kisio.layerGroup[section][layerKey].clearLayers();
            }
        });
    })

    if (window.leaflet.markers) {
        window.leaflet.markers?.to?.removeFrom(window.leaflet.map);
        window.leaflet.markers?.from?.removeFrom(window.leaflet.map);
    }
}

export function hideLayersSection (section, clean_data=false) {
    // Remove section LayerGroup from map
    Object.keys(Kisio.layerGroup[section]).forEach((layerKey) => {
        let layer = Kisio.layerGroup[section][layerKey];
        layer.removeFrom(window.leaflet.map);

        if (clean_data == true) {
            Kisio.layerGroup[section][layerKey].clearLayers();
        }
    })
}

export function removeByLayerKey (target_layer_key =null, clean_data=false) {
    let sections = Object.keys(Kisio.layerGroup);

    sections.forEach((section) => {
        Object.keys(Kisio.layerGroup[section]).forEach((layerKey) => {

            if(target_layer_key !== null && target_layer_key !== layerKey) {
                return;
            }
            Kisio.layerGroup[section][layerKey].removeFrom(window.leaflet.map);

            if (clean_data == true) {
                Kisio.layerGroup[section][layerKey].clearLayers();
            }
        });
    })
}


// Show section layers
export function displayLayersSection(section) {
    clearMap(false);
    Object.keys(Kisio.layerGroup[section]).forEach((layerKey) => {
        let layer = Kisio.layerGroup[section][layerKey];
        layer.addTo(window.leaflet.map);
    });
}

// toggleLayersByIds
export const toggleLayersByIds = (linesId, section, layerGroupId) => {
    Object.keys(Kisio.layerGroup[section]).forEach((key) => {
        if (linesId.includes(key)) {
            Kisio.layerGroup[section][key].addTo(window.leaflet.map);
            return;
        }

        Kisio.layerGroup[section][key].removeFrom(window.leaflet.map);
    });
}

export const changeOpacityLayer = (section, layer, value) => {
    Object.keys(Kisio.layerGroup[section][layer]._layers).forEach(key => {
        let item = Kisio.layerGroup[section][layer]._layers[key];

        if (item.options.opacity !== 0 &&
            typeof item.setStyle === 'function'    
        ) {
            item.setStyle({
                opacity: value,
                fillOpacity: value
            })
        }
    })

}