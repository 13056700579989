export function Bookmark(data) {
  this.id = (data && data.id) || null;
  this.type = (data && data.type) || null;
  this.data = (data && data.data) || null;
}

Bookmark.prototype.getId = function () {
  return this.id;
};

Bookmark.prototype.setId = function (id) {
  this.id = id;
  return this;
};

Bookmark.prototype.getType = function () {
  return this.type;
};

Bookmark.prototype.setType = function (type) {
  this.type = type;
  return this;
};

Bookmark.prototype.getData = function () {
  return this.data;
};

Bookmark.prototype.setData = function (data) {
  this.data = data;
  return this;
};

Bookmark.prototype.getObject = function () {
  return {
    id: this.id,
    type: this.type,
    data: this.data,
  };
};
