export const line_config = {
    disruption_lines: '.disruption__lines',
    lines_item: '.disruption__lines-item',
    lines_item_container: 'disruption__lines-item-container',
    disruption_lines_mode: '.disruption__lines-by-mode',
    selected_line: '#traffic-home__autocomplete-item-selected',
    input_value_is_empty: 'traffic_input_value_is_empty',
    input_cleared: '#traffic-home__autocomplete-cleared',
    disruption_no_content: 'disruption__no-content',
    active_disruptions: '.active-disruptions',
    future_disruptions: '.future-disruptions',
    reset_modes: 'reset_traffic_modes',
    reset_networks_list: 'reset_traffic_network_list',
    show_disruption_by_network_id: 'show_disruption_by_network_id',
    show_all_network_disruptions: 'show_all_network_disruptions',
    traffic_content: 'traffic_content',
    traffic_section: '.ctp-traffic',
    line_back_class: '.disruption__lines-container-back',
    display_layers_on_map_event: 'display_layers_on_map_event',
    display_lines_on_map_event: 'display_lines_on_map_event',
    display_all_lines_on_map_event: 'display_all_lines_on_map_event'
}
