import { BookmarkClient } from "./bookmark-client";
import { LocalStorageBookmarkClient } from "./local-storage-bookmark-client";
import { getProviderName } from "../config";
import { BookmarkClientInterface } from "./bookmark-client-interface";

const LOCAL_STORAGE_PROVIDER = "localStorage";

export function BookmarkClientFactory() {
  this.provider = getProviderName();
}

/**
 *
 * @returns {BookmarkClientInterface|BookmarkClient|LocalStorageBookmarkClient}
 */
BookmarkClientFactory.prototype.createClient = function () {
  if (
    this.provider.toLocaleLowerCase() ===
    LOCAL_STORAGE_PROVIDER.toLocaleLowerCase()
  ) {
    return new LocalStorageBookmarkClient();
  } else {
    return new BookmarkClient();
  }
};
