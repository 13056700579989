const globalConfig = {
  cache: true,
  enabledBookmark: null,
  userConsent: null,
  rootContentId: "bookmark_container",
  providerName: "localStorage",
  env: "dev",
};

export function getCache() {
  return globalConfig.cache;
}

export function getEnabledBookmark() {
  return globalConfig.enabledBookmark;
}

export function getUserConsent() {
  return globalConfig.userConsent;
}

export function getRootContentId() {
  return globalConfig.rootContentId;
}

export function getProviderName() {
  return globalConfig.providerName;
}

export function getEnv() {
  return globalConfig.env;
}

export function setConfig({
  cache,
  enabledBookmark,
  userConsent,
  rootContentId,
  providerName,
  env,
}) {
  if (cache !== undefined) globalConfig.cache = cache;
  if (enabledBookmark !== undefined)
    globalConfig.enabledBookmark = enabledBookmark;
  if (userConsent !== undefined) globalConfig.userConsent = userConsent;
  if (rootContentId !== undefined) globalConfig.rootContentId = rootContentId;
  if (providerName !== undefined) globalConfig.providerName = providerName;
  if (env !== undefined) globalConfig.env = env;
}
