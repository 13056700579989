import {line_config} from "./constants";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";
import {dispatchDisplayedLinesEvent} from "./index";
import {showLine} from "../map/index";
import {mapPopupConfig} from "pnp_core_bundle/plugnplay/leaf-core";
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";
import {traffic_config} from "../../core/constants";

const lines_config = {
    item: '.disruption__lines-item',
    display_layers_on_map_event: 'display_layers_on_map_event',
    display_all_lines_on_map_event: 'display_all_lines_on_map_event',
    layer_section: 'traffic-home',
    loader: 'loading-box'
}


export function manageDisruptionLineDetails() {
    document.querySelectorAll(line_config.lines_item)?.forEach(function (line) {
        // Jquery use a custom event handler, managing much better event call and remove. however, can be made in vanilla
        JKisio(line).off('click').on('click', function() {
            if(Kisio.traffic_all_line_activated == false) {
                showLine(line.dataset.lineId).then(() => {});
            }
            if (line.nextElementSibling.classList.contains(line_config.lines_item_container)) {
                // quickfix
                let selector ='#traffic_content .simplebar-content';

                const trafficContent = (document.querySelector(selector)) ? document.querySelector(selector) :  document.getElementById(line_config.traffic_content);
                const trafficSection = trafficContent.querySelector(line_config.traffic_section);
                const lineDetail = line.nextElementSibling.cloneNode(true);
                lineDetail.classList.remove('gl-hide');
                try {
                    new SimpleBar(lineDetail);
                } catch (e) {
                    console.log(e);
                }
                clickOnReturnEventListeners(lineDetail, trafficSection);
                trafficContent.appendChild(lineDetail);
                trafficSection?.classList.add('gl-hide');

                executeIfMapExist(() => {
                    Object.values(Kisio.layerGroup.traffic[line.dataset.lineId]._layers).forEach((group)  => {
                        Object.values(group._layers).forEach((layer) => {
                            if (!(layer instanceof L.CircleMarker)) {
                                window.leaflet.map.fitBounds(layer.getBounds(), mapPopupConfig());
                            }
                        })
                    });
                });

                document.body.dispatchEvent(new CustomEvent(line_config.display_layers_on_map_event, {
                    detail: {
                        linesId: line.dataset.lineId,
                        section: "traffic",
                        layerGroupId: "trafficLayers"
                    }
                }));
            }
        });
    });
}

function clickOnReturnEventListeners(element, trafficSection) {
    
    element?.querySelector(line_config.line_back_class)?.addEventListener('keyup', function (event) {
        if (event.keyCode == 32 || event.keyCode == 13) {
            lineBackAction(element, trafficSection);
        }
    });
    
    element?.querySelector(line_config.line_back_class)?.addEventListener('click', function () {
        lineBackAction(element, trafficSection);
    });
}

function lineBackAction (element, trafficSection) {
    if(Kisio.traffic_all_line_activated == false) {
        document.dispatchEvent(new CustomEvent('clear_map', {detail: {clean_data: true}}));
    }

    element.remove();
    trafficSection?.classList.remove('gl-hide');
    dispatchDisplayedLinesEvent();
    const trafficContent = document.querySelector(traffic_config.traffic_content);
    trafficContent.SimpleBar.recalculate();
}

export function manageParamLineId() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of urlParams) {
        params[key] = value;
    }

    if ('line' in params) {
        document.querySelector(`[data-line-id="${params.line}"]`)?.click();
    }
}